@import 'src/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.results-slider {
  & .slick-prev,
  & .slick-next {
    width: 50px;
    height: 200px;
    &::before {
      color: $gray-400;
      font-size: 85px;
      font-weight: bold;
    }
  }
  .slick-prev {
    left: -85px;
    &::before {
      content: '\3008';
    }
  }
  .slick-next {
    right: -55px;
    &::before {
      content: '\3009';
    }
  }
}
