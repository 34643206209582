$color_white: #ffffff;
$color_black: #000000;
$color_background: #fbfafa;
$color_title: #0c15a0;
$color_blue_background: #2f38cd;
$color_content: #777777;
$color_footer: #202020;
$color_menu: #b6b6b6;
$color_underline: rgba(151, 176, 238, 0.6);
$color_focus: #e8e8ee;
$color_border_light: #f1f1f1;
$color_border_default: #e6e6e6;
$color-form-background: #f7f6f6;

$color_red: #dc3545;
$color_green: #84af4b;

$color_disabled: $color_menu;
$color_link_hover: $color_menu;
