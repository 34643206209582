// 👾 Spacings
$spacer: 16px;

$spacings: (
  0: 0px,
  1: 0.25 * $spacer,
  2: 0.5 * $spacer,
  3: $spacer,
  4: 1.5 * $spacer,
  5: 3 * $spacer,
  auto: auto,
);
$spacing-0: map-get($spacings, 0);
$spacing-1: map-get($spacings, 1);
$spacing-2: map-get($spacings, 2);
$spacing-3: map-get($spacings, 3);
$spacing-4: map-get($spacings, 4);
$spacing-5: map-get($spacings, 5);
$spacing-auto: map-get($spacings, auto);
