@import "src/styles/variables";

$char_size: 21px;
$margin_top: 20px;
$color_title_without_transparency: #97b0ee;

.playerAlphabet {
  border-top: $cell-border-content;
  border-bottom: $cell-border-content;

  .ch-grid {
    margin: -$margin_top / 2 0px $margin_top 0px;
    padding: 0;
    list-style: none;
    display: block;
    text-align: center;
    width: 100%;
    li {
      width: $char_size + 3;
      height: $char_size + 3;
      margin-right: 0.22rem !important;
      display: inline-block;
      .ch-item {
        position: relative;
        cursor: default;
        .ch-info-wrap {
          position: absolute;
          border-radius: 50%;
          perspective: 800px;
          transition: all 0.2s ease-in-out;
          top: $margin_top;
          .ch-info {
            font-family: $font_title;
            text-transform: uppercase;
            font-size: $font_size_default;
            width: $char_size;
            height: $char_size;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            transform-style: preserve-3d;
            div {
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-position: center center;
              backface-visibility: hidden;
            }
            .ch-info-front {
              color: $color_content;
            }
            .ch-info-back {
              transform: rotate3d(0, 1, 0, 180deg);
              background: $color_title_without_transparency;
              color: $color_white;
              font-weight: 700;
            }
            .ch-info-selected {
              background: $color_title_without_transparency;
              color: $color_white;
              font-weight: 700;
            }
          }
        }
        &:hover {
          .ch-info:not(.selected) {
            transform: rotate3d(0, 1, 0, -180deg);
          }
        }
      }
    }
  }
}
