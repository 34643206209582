.table-classement {
  .rangLabel {
    font-size: 8px;
  }

  .img-force {
    object-fit: contain;
    height: 40px;
    width: auto;
  }
}
