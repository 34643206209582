@import 'src/styles/variables';

.team-page-header {
  .i-am-player-button {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: $color_title;
    border-radius: 14px;
    border: solid 2px #{$color_title};
    background: white;
  }
}

.points-popup {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.45px;
  color: #ffffff;
  border-radius: 14px;
  text-transform: uppercase;
  width: max-content;
  border: solid 2px #0c0f9e;
  background-color: #0c0f9e;
}

.blue-follow {
  color: #0c0f9e;
}
