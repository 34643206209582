@import 'src/styles/variables';

.resultBox {
  cursor: pointer;
  border: $cell-border-light;
  &:hover {
    border: $cell-border-light-hover;
  }
  background: $color_white;
  .dateBox {
    color: $color-content;
    border-bottom: $cell-border-light;
  }

  .resultRow {
    margin: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    .name-wrapper {
      flex: 1;
    }
    .score {
      font-size: 24px;
    }
  }
}
