$break-tablet: 768px;
$break-desktop: 1024px;

@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: -1 + $break-tablet) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-tablet) and (max-width: - 1 + $break-desktop) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (max-width: -1 + $break-desktop) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  }
}
