@mixin variant($selector) {
  @at-root #{$selector}#{&} {
    @content;
  }
}

$z-index-elements: children, parent, dropdown, menu, header, overlay, modal;
@mixin z-index($element) {
  @if ($element == modal) {
    z-index: 1301; //From material ui https://material-ui.com/customization/z-index/
  } @else {
    z-index: index($z-index-elements, $element);
  }
}
