@import "src/styles/variables";

.oml-login-container {
  margin-top: 50px;
  padding-bottom: 50px;
  border: $cell-border-default;
  background-color: $color-form-background;
  .slick-list {
    transition: height 0.5s ease;
  }
  .nav-tabs {
    border: none !important;
    .nav-item {
      display: flex;
      flex: 1;
      font-family: $font_title;
      .nav-link {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        background-color: white;
        position: relative;
        font-size: $font_size_intermediate;
        &.active {
          border: none;
          color: $color_title;
          background-color: inherit;
        }
        &:not(.active) {
          cursor: pointer;
        }
        .progress {
          position: absolute;
          top: 0;
          left: 0;
          height: 5px;
          background-color: transparent;
        }
      }
    }
  }
}
