@import "src/styles/variables";

$height-search: 23px;

.players-search-input {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border-radius: 0px;
    border: none;
    border-bottom: $cell-border-default;
    height: $height-search;
    background: transparent;
    padding: 0 10px;
    color: $color_content;
    font-family: $font_title;
    font-size: 12px;
  }
}
