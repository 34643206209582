@import 'src/styles/variables';

.page-header {
  font-family: $font_title;
  background-color: white;

  .header-logo {
    width: 120px;
    padding-left: 16px;
    padding-top: 16px;
    @include respond-to(desktop) {
      width: 180px;
      padding-left: 0;
      padding-top: 0;
    }
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  .header-name {
    text-transform: uppercase;
    font-size: $font_size_title;
    line-height: $font_size_title;
    font-weight: 800;
    color: $color_title;
  }

  .header-sub-name {
    letter-spacing: 0.9px;
    font-size: $font_size_intermediate;
    font-weight: bold;
    line-height: 1.34;
  }
}
