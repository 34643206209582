@import 'variables';

// Array of colors from _variables.scss
$theme-colors: (
  primary: $color_title,
) !default;

.btn {
  font-family: $font_title;
  &.btn-primary {
    text-transform: uppercase;
  }
  &.btn-light {
    text-transform: uppercase;
    color: $color_title;
    border-color: $color_title;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      color: #09107c;
      border-color: #09107c;
      background: transparent;
    }
  }
}
