.h1 {
  font-size: 50px;
  line-height: 55px;
  font-family: $font_title;
  font-weight: 600;
}
.h2 {
  font-size: 38px;
  line-height: 42px;
  font-family: $font_title;
  font-weight: 600;
}
.h3 {
  font-size: 28px;
  line-height: 32px;
  font-family: $font_title;
  font-weight: 600;
}
.h4 {
  font-size: 18px;
  line-height: 24px;
  font-family: $font_title;
  font-weight: 500;
}
.h5 {
  font-size: 16px;
  line-height: 22px;
  font-family: $font_title;
  font-weight: 500;
}
.h6 {
  font-size: 14px;
  line-height: 18px;
  font-family: $font_title;
  font-weight: 500;
}
.p {
  font-size: $font_size_default;
  font-family: $font_text;
  font-weight: 400;
}
.small {
  font-size: $font_size_small;
  font-family: $font_text;
  font-weight: 400;
}
.tiny {
  font-size: $font_size_tiny;
  font-family: $font_text;
  font-weight: 400;
}
.big {
  font-size: 22px;
  line-height: 34px;
  font-family: $font_text;
  font-weight: 400;
}

.font-intermediate {
  font-size: $font_size_intermediate;
}

.font-title {
  font-size: $font_size_title;
}
.font-subtitle {
  font-size: $font_size_subtitle;
}
.font-default {
  font-size: $font_size_default;
}
