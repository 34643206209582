@import 'src/styles/variables';

$img_width: 250px;
$img_height: 118px;

.actu-card {
  background: $color_white;
  border: $cell-border-light;
  img {
    width: 100%;
    height: auto;
  }
  @mixin actu-title($stroke-color) {
    color: $stroke-color;
    font-family: $font_title;
    .actu-type {
      font-size: 37px;
      line-height: 37px;
      font-weight: bold;
      margin-left: -15px;
      text-transform: uppercase;
    }
    .info-stroke {
      padding-top: 5px;
      font-size: 11px;
      line-height: 11px;
      display: inline-block;
      color: white;
      transform: rotate(-90deg);
      // Généré depuis http://owumaro.github.io/text-stroke-generator/
      text-shadow: #{$stroke-color} 1px 0px 0px, #{$stroke-color} 0.540302px 0.841471px 0px,
        #{$stroke-color} -0.416147px 0.909297px 0px, #{$stroke-color} -0.989992px 0.14112px 0px,
        #{$stroke-color} -0.653644px -0.756802px 0px, #{$stroke-color} 0.283662px -0.958924px 0px,
        #{$stroke-color} 0.96017px -0.279415px 0px;
    }
  }
  .actu-type-row {
    @include actu-title(#f0e724); // Divers by default
    &.club {
      @include actu-title(#f07f05);
    }
    &.joueur {
      @include actu-title(#63c2c4);
    }
    &.championnat {
      @include actu-title(#e7193f);
    }
    &.equipe {
      @include actu-title(#0faf92);
    }
  }

  .border-top-grey {
    border-top: $cell-border-default;
  }
  // To embed youtube videos
  figure.media > div {
    width: 100%;
  }
}
