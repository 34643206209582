$font_title: 'Poppins';
$font_text: 'Fira sans';
$font_underline_title: 'Anton';

$font_size_default: 14px;
$font_size_subtitle: 16px;
$font_size_title: 24px;
$font_size_intermediate: 18px;
$font_size_small: 12px;
$font_size_tiny: 10px;

$font_size_menu: 14px;
$font_size_menu_mobile: 18px;
