@import-normalize;

@import '_custom_bootstrap';
@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'variables';
@import 'omlTable';
@import 'omlHtmlElements';
@import 'pagination';
@import 'text';
@import 'colors';
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:100,400,700|Poppins:100,300,400,600,700,900|Anton');

.app {
  padding-top: $navbar_height;
}

.fullHeight {
  min-height: calc(100vh - #{$navbar_height});
}

.oml-title {
  color: $color_title;
  text-transform: uppercase;
  font-family: $font_title;
  font-size: $font_size_title;
  letter-spacing: 2px;
  font-weight: bold;
}

.color-title {
  color: $color_title;
}

.fw-400 {
  font-weight: 400;
}

.container {
  max-width: 1000px !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.img-favoris {
  object-fit: contain;
  height: 45px;
  width: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.single-line {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.no-wrap {
  white-space: nowrap;
}

.form-group {
  margin-bottom: 0;
  display: inline-block;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: 1 1 auto;
}

.mr-24 {
  margin-right: 1.5rem;
}
.mr-32 {
  margin-right: 2rem;
}
.ml-32 {
  margin-left: 2rem;
}

.overflow-scroll {
  overflow: scroll;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-title {
  width: 100%;
  padding: 10px;
  font-family: $font_title;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #ffffff;
  background-color: $color_blue_background;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.image-thumb {
  width: auto;
  height: 40px;
  object-fit: cover;
  vertical-align: bottom;
  padding-bottom: 0;
}

.cover {
  background-size: cover;
}

/* Chrome, Safari, Edge, Opera */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.no-arrows[type='number'] {
  -moz-appearance: textfield;
}

.Toastify__toast--success {
  background-color: $color_title;
}

button {
  font-family: $font-title;
}

.oml-table thead {
  th,
  td {
    &.no-bottom-padding {
      padding-bottom: 0;
    }
    &.no-top-padding {
      padding-top: 0;
    }
    &.no-x-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
