@import "./variables";

.react-bootstrap-table-pagination {
  > div {
    min-width: 100% !important;
    .pagination {
      margin-top: 35px;
      .page-item {
        $page-item-size: 18px;
        width: $page-item-size;
        height: $page-item-size;
        text-align: center;
        &[title~="page"] {
          .page-link {
            color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 50%;
          }
        }
        &[title="first page"] {
          .page-link {
            background-image: url("/assets/img/pagination/premier.png");
          }
        }
        &[title="previous page"] {
          .page-link {
            background-image: url("/assets/img/pagination/avant.png");
          }
        }
        &[title="next page"] {
          .page-link {
            background-image: url("/assets/img/pagination/apres.png");
          }
        }
        &[title="last page"] {
          .page-link {
            background-image: url("/assets/img/pagination/dernier.png");
          }
        }

        &.active {
          .page-link {
            background-color: $color-border-default;
            color: white;
          }
        }
        .page-link {
          border-radius: unset;
          border: none;
          background-color: unset;
          color: $color_content;
          font-size: 12px;
          padding: 0;
          line-height: $page-item-size;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
