@import "src/styles/variables";

.follow-us-bar {
  background-color: $color_footer;
  color: $color_white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
}
