@import 'src/styles/variables';

%height_100 {
  height: 100% !important;
}
$slide_height: 600px;
$slide_height_mobile: 100vh;
$image_left_offset: -10%;

$font_size_legend: 16px;
$font_size_jumbo: 48px;
$font_size_feature_text: 22px;

$color_features_text: $color_white;

.home-slider {
  height: $slide_height;
  @include respond-to(mobile) {
    height: $slide_height_mobile;
  }
  .row-without-container {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 -15px;
  }
  .feature-slide {
    height: $slide_height;
    @include respond-to(mobile) {
      height: $slide_height_mobile;
    }
  }
  .slick-slider {
    @extend %height_100;
  }
  .slick-track {
    @extend %height_100;
  }
  .slick-list {
    @extend %height_100;
  }
  .slick-slide {
    @extend %height_100;
  }
  .slide-background {
    display: none;
  }
  .slick-dots {
    position: relative;
    top: -(1.5 * $font_size_legend + 1/6 * $slide_height);
    padding: 0px;
    color: $color_features_text;
    text-transform: uppercase;
    font-size: $font_size_legend;
    text-align: left;
    line-height: $font_size_legend;
    letter-spacing: 2px;
    @include respond-to(mobile) {
      font-size: $font_size_default;
      letter-spacing: normal;
    }
    @include respond-to(tablet) {
      top: -(50/100 * $slide_height_mobile);
    }
    @include respond-to(phone) {
      top: -(40/100 * $slide_height_mobile);
    }
    .slick-active {
      border-bottom: 2px solid $color_features_text;
    }
    li {
      width: unset;
      margin-right: 30px;
      @include respond-to(mobile) {
        margin-right: 20px;
      }
    }
    li:last-child {
      margin-right: 0px;
    }
  }
  h4 {
    font-size: $font_size_jumbo;
    font-family: $font_title;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color_features_text;
    margin-top: 60px;
    margin-bottom: 70px;
    @include respond-to(mobile) {
      font-size: $font_size_jumbo - 10;
      margin-bottom: 30px;
    }
  }
  .list_description p {
    font-size: $font_size_feature_text;
    color: $color_features_text;
    line-height: 36px;
    @include respond-to(mobile) {
      font-size: $font_size_default;
    }
  }
  .background-slider {
    width: 7 / 12 * 100%;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
  .image-slider {
    position: relative;
    left: $image_left_offset;
    width: 5 / 12 * 100%;
    @include respond-to(mobile) {
      left: 0px;
      top: calc(20px - #{$slide_height_mobile});
      width: 100%;
    }
    .slick-slide {
      img {
        width: auto !important;
        height: 2/3 * $slide_height;
        object-fit: cover;
        max-width: 100%;
        @include respond-to(tablet) {
          height: 40/100 * $slide_height_mobile;
          margin-left: auto;
        }
        @include respond-to(phone) {
          height: 30/100 * $slide_height_mobile;
          margin-left: auto;
        }
      }
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }
  }
  .text-slider {
    position: relative;
    height: $slide_height;
    top: -$slide_height;
    @include respond-to(mobile) {
      height: $slide_height_mobile;
      top: -1.15 * $slide_height_mobile;
    }
    .slide-text {
      height: 100%;
      padding: 1/6 * $slide_height 0;
      display: flex;
      flex-direction: column;
      @include respond-to(mobile) {
        padding-bottom: 2/10 * $slide_height_mobile;
      }
    }
  }
}
