@import 'src/styles/variables';

// TODO a supprimer

.player-image {
  width: 146px;
  height: 100%;
}

.shirt {
  font-size: 60.5px;
  font-weight: 900;
  color: #0c15a0;
}

.post {
  font-size: 18px;
  line-height: 1.34;
  letter-spacing: 0.9px;
  color: var(--warm-grey);
}

.name {
  font-size: 30px;
  font-weight: 600;
  line-height: 0.8;
  color: #0c15a0;
}

.team-image {
  width: 67px;
  height: 90px;
}

.team-name {
  font-size: 14px;
  line-height: 1.72;
  letter-spacing: 0.7px;
  color: var(--warm-grey);
}

.blue-row .subHeading {
  color: white !important;
  text-align: center !important;
  background-color: #{$color_blue_background} !important;
}

.editIconImg {
  width: 30px;
  float: right;
  cursor: pointer;
}

.registerButton {
  font-family: FiraSans;
  font-size: 12px;
  font-weight: 600;
  line-height: 4;
  letter-spacing: 1.2px;
  background-color: #ffffff;
  width: 100%;
  color: #0c15a0;
  cursor: pointer;
}

.total {
  font-size: 65px;
  font-weight: 600;
  font-stretch: normal;
}

.green {
  color: green;
}

.red {
  color: red;
}

.right-evl-title {
  float: right;
  font-family: FiraSans;
  font-size: 10px;
  font-weight: 600;
}

.skill-title {
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #0c15a0;
  text-transform: uppercase;
}

.skill-total {
  font-size: 65px;
  font-weight: 600;
}

.like-img {
  height: max-content;
}

.skill {
  font-weight: 600;
}

.career-popup {
  font-size: 11px;
  line-height: 2.19;
  letter-spacing: 0.55px;
  color: #0c15a0;
  float: right;
  border-radius: 14px;
  border: solid 1px #0c0f9e;
}

.member-stat-table thead {
  background-color: $color_blue_background;
  color: $color_white;
}
