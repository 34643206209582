@import 'src/styles/variables';

.scoreBox {
  background-image: url('/assets/img/game/bg-asset.png');
  background-repeat: no-repeat, repeat;
  height: 274px;
  background-position: 0 0;
  background-size: cover;
}

.scorePadding {
  width: 100%;
  font-family: $font_title;
  font-size: $font_size_title;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $color_white;
  display: inline-block;
}
.leftMargin {
  margin-left: 20px;
}
#gameRating {
  .col-11.col-md-11.col-lg-11 {
    padding-left: 0px;
  }
}
.addMargin {
  margin-top: 40px;
}
.progessContainer {
  flex-direction: column;
  align-items: center;
}
.block {
  display: block;
}
.cursor {
  cursor: default;
}
.teamLogoGame {
  height: 85px;
  object-fit: contain;
}
.scoreTeamName {
  text-transform: uppercase;
  font-size: $font_size_subtitle;
  color: $color_white;
  font-weight: bold;
  font-family: $font_title;
}
.gameButton {
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
  background-color: $color_title;
  text-transform: uppercase;
  font-size: $font_size_default;
  color: $color_white;
  border-radius: 20px;
  &.ghost {
    text-decoration: underline;
    background-color: transparent;
    color: white;
    border: none;
  }
}
.tableTitle {
  background-color: $color_blue_background;
  color: #ffffff;
}
.name-detail {
  display: flex;
  font-size: small;
}
.performance-detail {
  display: flex;
  justify-content: space-between;
  font-size: small;
}
.image {
  height: 100%;
}
.playerImage {
  padding: 3px;
}
.cursor:hover {
  color: $color_blue_background;
  font-weight: bold;
}
.bold {
  color: $color_blue_background;
  font-weight: bold;
}
.toolTip {
  position: absolute;
  top: -29px;
  left: 5px;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100px;
  background-color: white;
  border: 1px solid #{$color_blue_background};
  box-shadow: 0px 3px 4px 0px rgba(235, 235, 235, 1);
  border-radius: 3px;
  height: auto;
  padding: 4px;
}
.toolTip::before {
  content: '';
  position: absolute;
  left: 82px;
  top: 23px;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-bottom: 1px solid #{$color_blue_background};
  border-right: 1px solid #{$color_blue_background};
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.verticalCenter span,
.verticalCenter a {
  align-items: center;
  display: inline-flex;
}
.teamName {
  padding: 10px 0px 10px 0px;
}

.rating-bar-gradient {
  $GRADIENT_START_COLOR: $color_title;
  $GRADIENT_END_COLOR: #f23148;
  background: $GRADIENT_START_COLOR;
  background: -webkit-linear-gradient(to right, $GRADIENT_START_COLOR, $GRADIENT_END_COLOR);
  background: linear-gradient(to right, $GRADIENT_START_COLOR, $GRADIENT_END_COLOR);
}
