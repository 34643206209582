body {
  padding: 0;
  background-color: $color_background;
  font-family: $font_text, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: $font_size_default;
  color: $color_content;
  scroll-behavior: smooth;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.styled-text {
  ul > li {
    list-style: disc;
    margin-left: 1rem;
  }
  ol > li {
    list-style: auto;
    margin-left: 1rem;
  }
  blockquote {
    //Style exporté de ckeditor
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
}

a,
.anchor {
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
    &:not(.disable-hover) {
      color: $color_link_hover;
    }
  }
  &.color-inverted {
    color: white;
  }
  &.uppercase-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $font_title;
    font-weight: bold;
  }
}

img {
  max-width: 100%;
  height: auto;
}
