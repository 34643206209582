@import "src/styles/variables";

.oml-footer {
  $font-size-footer: 12px;
  background-color: $color_footer;
  margin-top: 50px;
  font-weight: 300;
  font-family: $font_title;
  color: white;
  .logo {
    width: 299px;
    height: auto;
  }
  a {
    color: $color_content;
    &:hover {
      color: white;
    }
  }
  span,
  a {
    display: inline-block;
  }
  .row-items {
    letter-spacing: 2px;
    font-size: $font-size-footer;
    font-size: 10px;
    margin-bottom: 80px;
    img {
      height: 45px;
    }
  }
  .row-copyright {
    font-size: 10px;
    padding-bottom: 30px;
    letter-spacing: 2px;
  }
}
