@import 'src/styles/variables';

.oml-menu {
  $logo_height: 50px;
  $sub_menu_height_championnat: 285px;
  $sub_menu_height_equipe: 285px;
  $sub_menu_height_actu: 285px;
  $sub_menu_height_sport: 210px;

  $tabs-width: 200px;

  $espacement: 2rem;

  $color_background_menu: $color_white;
  $menu-mobile-title-height: 40px;

  font-family: $font_title;
  font-size: $font_size_menu;

  position: fixed;
  width: 100%;
  @include z-index(menu);
  .title {
    text-transform: uppercase;
    padding: 10px 0;
    font-weight: 600;
  }

  a {
    color: $color_content;
  }
  .oml-navbar {
    min-height: $navbar_height;
    background: $color_background_menu !important;
    color: $color_menu;
    padding: 0.5rem 2rem;

    .navbar-brand {
      .logo {
        height: $logo_height;
      }
    }
    .navbar-toggler {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

  .oml-nav {
    flex: 1;
    flex-direction: column;
    @include respond-to(desktop) {
      flex-direction: row;
    }
    @include respond-to(mobile) {
      font-size: $font_size_menu_mobile;
      overflow: scroll;
      height: calc(100vh - #{$navbar-height});
      li {
        padding: 0 2rem;
      }
    }
  }

  .nav-item {
    text-transform: uppercase;
    color: $color_title;
    letter-spacing: 2px;
    &:hover {
      color: inherit;
    }
    @include respond-to(mobile) {
      position: relative;
      border-bottom: $cell-border-default;
      &:hover {
        color: $color_title;
      }
      & > div {
        height: $menu-mobile-title-height;
      }
      .menu-label {
        min-width: 130px;
        display: inline-block;
      }
    }
  }

  .voile-noir {
    display: none;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
  }
  .sub-menu-container {
    height: 0;
    width: 100%;
    transition: height 0.2s;
    position: fixed;
    top: $navbar_height;
    z-index: 3;
    &.championnats {
      height: $sub_menu_height_championnat;
    }
    &.sports {
      height: $sub_menu_height_sport;
    }
    &.equipes {
      height: $sub_menu_height_equipe;
    }
    &.favoris {
      height: $sub_menu_height_championnat;
    }
  }
  &.collapsing {
    .sub-menu-wrapper {
      overflow: hidden;
    }
  }
  .sub-menu-wrapper {
    position: absolute;
    z-index: 3;
    left: 0;
    font-size: $font_size_menu;
    background: $color_background_menu;
    width: 100%;
    height: 100%;
    .separator {
      min-width: 3px;
      height: calc(#{$sub_menu_height_championnat} - 2rem);
      background-color: #f0f0f0;
    }
  }
  .sports {
    text-transform: uppercase;
    @include respond-to(mobile) {
      padding: 0 10px 10px;
    }
  }
  .championnats,
  .favoris {
    display: flex;
    .subMenu-tabs {
      min-width: $tabs-width;
      text-transform: uppercase;
      .nav-item {
        padding: 10px 0px;
        height: 50px;
        .nav-link {
          padding: 0;
        }
      }
    }
    svg {
      vertical-align: baseline;
    }
  }
  .liste-result {
    padding: 0 25px;
    &.wide {
      width: 358px;
    }
    .title {
      text-transform: uppercase;
      padding: 10px 0;
      font-weight: 600;
    }
    ul {
      padding-left: 0px;
      li {
        list-style-type: none;
        a {
          line-height: 22px;
        }
      }
    }
  }
  form {
    display: flex;
    @include respond-to(mobile) {
      flex-direction: column;
    }
    .form-group {
      display: flex;
      align-items: flex-end;
      @include respond-to(mobile) {
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-right: 45px;
        @include respond-to(mobile) {
          margin-right: 0;
        }
      }
      label {
        text-transform: uppercase;
        margin-right: 15px;
        font-weight: bold;
        @include respond-to(mobile) {
          min-width: 75px;
          font-weight: unset;
        }
      }
    }
  }
  .champ-form {
    padding: 10px 25px;
  }
  .region {
    width: 180px;
  }
  .genre {
    width: 100px;
  }
  .category {
    width: 100px;
  }
  .ville {
    width: 190px;
  }
  .club {
    width: 230px;
  }

  .sub-menu-items {
    .sub-menu-item {
      padding: 1rem $espacement;
      font-size: $font_size_menu_mobile * 0.7;
      text-transform: uppercase;
      &.with-border {
        border-bottom: $cell-border-default;
      }
      svg {
        font-size: $font_size_menu_mobile * 0.7;
      }
      &.level-2 {
        padding-left: 2 * $espacement;
      }
      &.level-3 {
        padding-left: 3 * $espacement;
      }
    }
  }
}

.teaser-list {
  $img_height: 117px;
  $img_width: 186px;
  // TODO .img-container à supprimer
  .img-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .img-anim {
      cursor: pointer;
      width: $img_width;
      &.disabled {
        cursor: default;
        img {
          filter: brightness(0.4);
        }
        + .titre {
          color: $color_disabled;
        }
        @at-root .sports &:after {
          background: rgba(255, 255, 255, 0.6);
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        height: $img_height;
        position: relative;
      }
    }
    .titre {
      color: $color_title;
      font-size: $font_size_menu;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      margin: auto;
      &.over {
        position: absolute;
        color: $color_white;
        top: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
    }
  }
  .img-anim {
    min-height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: inline-block;
      transform: scale(1.09, 1.09);
      transition-duration: 0.4s;
      object-fit: cover;
    }
    &:hover {
      // opacity: 1;
      transform: scale(1, 1);
      transition-duration: 0.6s;
      img {
        transform: scale(1, 1);
        background-position: top top;
        // filter: opacity(0.4) blur(2px);
        transition-duration: 0.8s;
        // opacity: 0.9;
      }
    }
  }
}

.btn-secondary {
  color: #fff;
  background-color: none;
  border-color: none;
}
