@import 'src/styles/variables';

.championnat {
  .sub-title {
    font-family: $font_title;
    font-size: $font_size_default;
    text-transform: uppercase;
  }
  .nav {
    border-top: $cell-border-default;
    border-bottom: $cell-border-default;
    scroll-behavior: smooth;
    .nav-item {
      font-family: $font_title;
      text-transform: uppercase;
      cursor: pointer;
      color: $color_title;
      letter-spacing: 2px;
      &.active {
        font-weight: bold;
      }
      &:hover,
      &:active {
        color: $color_link_hover;
      }
      @include respond-to(mobile) {
        width: unset;
        white-space: nowrap;
      }
    }
  }

  .oml-favoris img:hover {
    cursor: pointer;
  }

  .title2 {
    font-family: $font_underline_title;
    letter-spacing: 1px;
    font-size: 24px;
    color: #0c15a0;
    text-transform: uppercase;
    margin-top: -15px;
    display: block;
  }
}
