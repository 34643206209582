@import './variables';

.react-bootstrap-table {
  // Possibilité de scroller pour les mobiles lorsque les tableaux sont trop grands
  width: 100%;
  overflow: auto;

  .oml-table {
    table-layout: fixed;
    word-wrap: break-word;
    min-width: 720px;
    &.no-min-width {
      min-width: unset;
    }
    thead {
      border-bottom: $cell-border-default !important;
      th {
        border: none;
        font-family: $font_title;
        font-weight: 400;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
    tbody {
      border-bottom: $cell-border-default !important;
      td {
        vertical-align: middle;
      }
    }
  }
  .capitalized-link {
    font-weight: 700;
    text-transform: capitalize;
  }
}
