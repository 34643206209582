@import 'src/styles/variables';

input.oml-input,
textarea.oml-input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: $cell-border-content;
  padding-left: 0;
  padding-right: 0;
  font-size: inherit;
  &:focus,
  &:active {
    background-color: transparent;
    color: $color-title;
  }
  & + .display-password {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1.25rem;
    display: flex;
    align-items: center;
  }
  & + .display-sufix {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    display: flex;
    align-items: center;
  }
  &.filled {
    color: $color-title;
  }
  &[type='password'] {
    letter-spacing: 5px;
    &::placeholder {
      letter-spacing: normal;
    }
  }
  &.is-invalid {
    background-image: none;
  }
  @at-root .position-relative & {
    padding-right: 1rem;
  }
}

textarea.oml-input::placeholder {
  color: $color_content;
}
